const CryptoJS = require('crypto-js')  //引用AES源码js

const key = CryptoJS.enc.Utf8.parse('1234123412ABCDEF')  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412')   //十六位十六进制数作为密钥偏移量
//解密方法
export function Decrypt(word) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    const decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
}

//加密方法
export function Encrypts(pw) {
   if (pw) {
     const secreKey = CryptoJS.enc.Utf8.parse('KXT23456!8888888')
     const pwd = CryptoJS.enc.Utf8.parse(pw.toString())
     const res = CryptoJS.AES.encrypt(pwd, secreKey, {
       mode: CryptoJS.mode.ECB,
       padding: CryptoJS.pad.Pkcs7,
     }).toString()
     return res
   }
}
