/**
 * @name: TCaptcha
 * @author: icon
 * @date: 2024/7/1 下午3:48
 * @description：TCaptcha
 * @update: 2024/7/1 下午3:48
 */
let script = document.querySelector('script#TCaptcha')
if(!script) {
  script = document.createElement('script')
  script.id = 'TCaptcha'
  script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
  document.body.appendChild(script)
}

export default {
  methods: {
    getCaptchaFn() {
      return new Promise(resolve => {
        try {
          // 生成一个验证码对象
          // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
          var captcha = new TencentCaptcha('192721144', (res) => {
            // 第一个参数传入回调结果，结果如下：
            // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
            // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
            // CaptchaAppId       String    验证码应用ID。
            // bizState    Any       自定义透传参数。
            // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
            console.log('callback:', res);
            // res（用户主动关闭验证码）= {ret: 2, ticket: null}
            // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
            // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
            // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
            if (res.ret === 0) {
              resolve({
                ticket: res.ticket,
                randstr: res.randstr
              })
            }
          }, {
            loading:false
          });
          // 调用方法，显示验证码
          captcha.show();
        } catch (error) {
          console.log(error)
          this.$message.error('验证不通过，请联系客服')
          resolve(null)
        }
      })
    }
  }
}
