import privacyPolicy from "@/views/privacyPolicy.vue"
import login from "@/views/login.vue"
import userAgreement from "@/views/userAgreement.vue"
import lianGlobal from "@/views/lianGlobal.vue"


import Vue from 'vue'
import Router from 'vue-router'
export const routes=[
	{path:'/',component:login},
	{path:'/privacyPolicy',component:privacyPolicy},
	{path:'/userAgreement',component:userAgreement},
	{path:'/lianGlobal',component:lianGlobal},
	]	
// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: routes
})