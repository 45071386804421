<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
  import Login from "./views/login.vue";
  import {
    addActivityAccess
} from './api/login'
  import qs from 'qs'

  export default {
    name: "App",
    components: {
      Login,
    },
    mounted() {
      if(location.search) {
        const search = location.search.slice(1)
        const query = qs.parse(search)
        if(query.qdSource && query.activityType) {
          addActivityAccess({
            qdSource: Number(query.qdSource),
            activityType: Number(query.activityType)
          }).catch(()=>{})
        }
      }
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  .awsc-modal .ant-modal-body .nc-container .nc_scale span {
    box-sizing: content-box;
  }
  .awsc-modal .ant-modal-body .nc-container #nc_1_wrapper {
    width: 100%;
  }
  .awsc-modal .ant-modal-body {
    position: relative;
    padding: 0;
    margin: 24px;
    margin-bottom: 0;
  }
</style>
