import Vue from 'vue'

let tnstance = null
let resolve = null

let script = document.querySelector('script#awsc')
if(!script) {
  script = document.createElement('script')
  script.id = 'awsc'
  script.src = 'https://g.alicdn.com/AWSC/AWSC/awsc.js'
  document.body.appendChild(script)
}

function newInstance(callback) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  new Vue({
    el: div,
    mounted: function mounted() {
      const self = this
      this.$nextTick(function () {
        tnstance = self
        callback(self)
      })
    },
    data() {
      return {
        visible: false,
      }
    },
    methods: {
      open(options = {}) {
        return new Promise((_resolve) => {
          resolve = _resolve
          const that = this
          this.visible = true
          if(window.nc) {
            window.nc.reset()
            return
          }
          AWSC.use("nc", function (state, module) {
            window.nc = module.init({
              appkey: "FFFF0N0000000000A380",
              scene: "nc_login",
              renderTo: "nc",
              width: 350,
              success: function (data) {
                that.visible = false
                options.callback && options.callback(data)
                resolve&&resolve(data)
                return
              },
              // 滑动验证失败时触发该回调参数。
              fail: function (failCode) {
                  window.console && console.log(failCode)
              },
              // 验证码加载出现异常时触发该回调参数。
              error: function (errorCode) {
                  window.console && console.log(errorCode)
              }
            });
          })
        })
      }
    },
    render: function render(h) {
      return h('a-modal',
        { props: {
          visible: this.visible,
          centered: true,
          title: '滑动验证码完成验证',
          bodyStyle: {height: '80px'},
          width: 400,
          maskClosable: false,
          footer: null,
          zIndex: 3000,
          wrapClassName: 'awsc-modal'
        }, on: { cancel:()=>{this.visible = false} }}, [h('div',{domProps: {id:'nc'}})])
    },
  })
}

function getInstance() {
  return new Promise((res, rej) => {
    if (tnstance) {
      res(tnstance)
      return
    }else {
      newInstance(function (_instance) {
        res(_instance)
      })
    }
  })
}

export default {
  install(_Vue) {
    _Vue.prototype.$awsc = {
      open: async function (options) {
        const tn = await getInstance(options)
        return tn.open(options)
      },
    }
  },
}
