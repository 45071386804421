import Vue from "vue";
import App from "./App.vue";
import router from './router'
Vue.config.productionTip = false;
import { Button, Message, Input, Form, FormItem, Checkbox,Dialog } from "element-ui";
import awsc from "common/plugins/awsc";
import Modal from 'ant-design-vue/lib/modal';
import 'ant-design-vue/lib/modal/style/css';

Vue.component(Button.name, Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(awsc);
Vue.use(Modal);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.prototype.$message = Message;
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
	router,
  render: (h) => h(App),
}).$mount("#app");
