function resetFontSize() {
  var windowW = document.documentElement.clientWidth || window.innerWidth;
  if (windowW >= 750) {
    windowW = 750;
  }

  if (windowW <= 320) {
    windowW = 320;
  }

  document.documentElement.style.fontSize = (windowW / 7.5) * 2 + "px";
}
window.addEventListener("resize", resetFontSize, false);
resetFontSize();
