import axios from "axios";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_URL,
  timeout: 6000, // 请求超时时间
});

// request.interceptors.response.use((res)=>{
//   return res.data
// },(error)=>{})

export default request;

