import request from '../request'

const api = {
  Register: '/auth/user/register',
  SendSms: '/auth/sendMsg',
}

export function addActivityAccess(parameter) {
  return request({
    url: '/activity/client/kxtactivityaccess/app/unauth/addActivityAccess',
    method: 'post',
    data: parameter,
  })
}
export function register(parameter) {
  return request({
    url: api.Register,
    method: 'post',
    data: parameter,
  })
}

export function getSmsCaptcha(parameter) {
  return request({
    url: api.SendSms,
    method: 'post',
    data: parameter,
  })
}
export function getWxConfig(params) {
  return request({
    url: '/auth/wechat/app/unauth/getWxConfig',
    method: 'get',
    params,
  })
}
// 获取
export function generatescheme(params) {
  return request({
    url: '/auth/wechat/app/unauth/generatescheme',
    method: 'get',
    params,
  })
}
// 访问次数
export function browseRecords(query) {
  return request({
    url: '/user/client/kxtUserInfo/browseRecords',
    method: 'get',
    params: query,
  })
}
