<template>
	<div class="privacyPolicy">
		<h3 class="title">跨信通隐私权政策</h3>
		<p>深圳跨信通科技有限公司（以下简称“跨信通”或“我们”）及其关联公司为保护您的用户信息安全可控，将按照适用的法律法规要求，对您的用户信息采取安全保护措施。因此，跨信通制定本《跨信通隐私权政策》（以下或简称“隐私权政策”）并提醒您：<b>在使用跨信通的各项服务前，请您务必仔细阅读并透彻理解本隐私权政策，在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用跨信通服务，将被视为对本隐私权政策的接受和认可。</b>跨信通（以下或称为“我们”）尊重并保护用户信息，并且将以高度勤勉和审慎的义务对待这些信息。在您使用了跨信通提供的服务时，我们将按照本隐私权政策收集、处理及披露您的信息。我们希望通过本隐私权政策向您清晰地介绍我们对您信息的处理方式，因此我们建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。
		</p>
		本隐私权政策主要包括以下内容：<br />
		一、适用范围<br />
		二、用户个人信息的收集与使用<br />
		三、用户个人信息共享、转让的方式<br />
		四、用户业务数据<br />
		五、用户个人信息的查询、更正与删除<br />
		六、用户个人信息的安全保护措施<br />
		七、未成年人用户个人信息的特别约定<br />
		八、隐私权政策的更新<br />
		九、我们的联系方式

		<h4>一、 适用范围</h4>
		<p>1. 本隐私权政策适用于跨信通网站提供的所有跨信通服务，包括跨信通基于互联网技术建立且享有著作权的公有云系统服务平台以及基于该云平台提供的各类企业互联网应用服务。
			<p />
		<p>2. 本隐私权政策不适用于其他第三方向您提供的服务。
			<p />

		<h4>二、 用户个人信息收集与使用</h4>
		<p><b>1. 我们使用您的个人信息的目的</b></p>
		1）为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知；<br />
		2）为了维护、改进服务，向您提供更符合您个性化需求的信息展示；<br />
		3）如我们超出本款约定使用您的用户个人信息，或者在超出具有合理范围使用您的用户个人信息前，我们会再次向您告知并征得您的明示同意。<br />

		<p><b>2. 我们收集您的个人信息的范围</b></p>
		<p>您的个人信息是指单独使用或结合其他信息使用时能够确定您个人身份的信息。您或您所在的企业组织用户注册、使用我们的网站、产品或服务时，我们会向其收集您的必要个人信息，具体如下：<br />
			1）注册账户时收集的信息<br />
			您在注册跨信通网站账户时，我们会收集您的如下信息：<br />
			2）按照《移动互联网应用程序信息服务管理规定》的要求，您如注册跨信通移动端APP账户，应按照 “后台实名、前台自愿”的原则进行实名认证。此时，我们会向您收集您的如下身份识别信息，包括：您的姓名、名称、身份证明、出生日期、民族、国籍、性别、政治面貌、资质证明、指定联系人、所在省份/城市、联系地址、邮政编码、手机号码、电子邮件等；<br />
			3）使用服务时收集的信息<br />
			您在使用跨信通服务时，我们会收集您的如下信息：<br />
			4）设备信息，包括：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备硬件信息（例如设备型号、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）；<br />
			5）其他根据跨信通的服务的需要而收集的您的信息，包括：您对跨信通服务的意见、建议。<br />
			<b>您理解并同意，如您作为企业组织管理员选择开通、管理跨信通服务的，您所在企业组织用户的个人用户使用跨信通服务相关功能/应用过程中可能会提交或产生相应的信息和数据（下称“企业控制数据”）。您确认并知晓，企业组织用户为上述企业控制数据的控制者，跨信通仅根据企业组织用户管理员选择开通、管理和使用上述应用服务处理您的个人信息/数据。企业组织用户上传上述个人用户的个人信息之前，应确保已经事先获得上述个人用户的明确同意，并仅限收集实现企业运营及管理目的所必需的个人用户的个人信息，并已充分告知上述个人用户有关相关数据收集的目的、范围及使用方式等。</b>
		</p>
		<p><b>3.我们收集您的个人信息的方式</b></p>
		1）我们将收集和储存在您注册、登录、浏览跨信通产品及/或使用跨信通服务时主动向跨信通提供的个人信息；<br />
		2）我们将收集和储存跨信通在向您提供跨信通服务的过程中记录的与您有关的个人信息；<br />
		3）我们将收集和储存您通过跨信通的客服人员及/或其他渠道主动提交或反馈的个人信息；<br />
		4）我们可能向依法设立的征信机构查询您的相关信用信息，如信用分、信用报告等。

		<h4>三、用户个人信息共享、转让方式</h4>
		<p><b>1. 共享</b></p>
		<p>我们不会与其他组织和个人共享您的用户信息，但以下情况除外：<br />
		1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；<br />
		2）在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；<br />
		3）对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。
		</p>

		<p>
			<b>2. 转让</b>
		</p>
		<p>我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：<br />
		1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；<br />
		2）在跨信通与其他法律主体者发生合并、收购情形，或其他涉及合并、收购情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
		</p>
		<p>
			<b>3. 共享、转让信息时事先征得授权同意的例外</b>
		</p>
		<p>以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：<br />
			1）与国家安全、国防安全有关的；<br />
			2） 与公共安全、公共卫生、重大公共利益有关的；<br />
			3）与犯罪侦查、起诉、审判和判决执行等有关的；<br />
			4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />
			5）您自行向社会公众公开的个人信息；<br />
			6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br />
		</p>
		<h4>四、 用户业务数据</h4>
		<p>不同于您的用户信息，对于用户业务数据和公开信息，跨信通将按如下方式处理：</p>
		<p>
			<b>1. 用户业务数据</b>
		</p>
		1）您通过跨信通提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。跨信通作为企业云服务提供商，我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。<br />
		2）您应对您的用户业务数据来源及内容负责，跨信通提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。<br />
		3）为了优化服务质量和向您提供新的产品与服务，跨信通会收集您于跨信通相关产品内的交易数据、交易过程与订单信息进行数据分析并将上述业务数据（敏感信息除外）共享给跨信通关联公司。<br />
		<h4>五、 用户个人信息的查询、更正与删除</h4>
		<p>您可以查看您提供给跨信通的注册信息及使用跨信通平台过程中产生的其他信息。如果您希望删除或更正您的信息，请通过跨信通的客户隐私专用邮箱（alice@kuaxintong.com）与我们联系。</p>
		<h4>六、用户个人信息的安全保护措施</h4>
		<p>1.我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的信息。</p>
		<p>2.我们会建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。</p>
		<p>3.我们会制定与信息安全相关的网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险。在发生或者可能发生信息泄露、丢失的情况时，我们会：</p>
		1）立即采取补救措施，按照法律法规和监管部门要求，上报信息安全事件的处置情况及时告知用户；<br />
		2）立即启动网络安全事件应急预案，对网络安全事件进行调查和评估，采取技术措施和其他必要措施，消除安全隐患，防止危害扩大；<br />
		3）及时将事件相关情况以邮件、信函、电话、推送通知等方式告知受影响的信息主体。难以逐一告知信息主体时，我们采取合理、有效的方式发布与公众有关的警示信息。<br />
		4）我们会采取合理可行的措施，尽力避免收集无关的信息，并在限于达成本《隐私及信息保护政策》所述目的所需的期限内保留您的信息，除非需要延长保留期限或在法律的允许期限内。<br />
		5）但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。<br />
		<h4>七、未成年人用户信息的特别约定</h4>
		<p>1. 我们主要面向成人提供产品和服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
		<p>2. 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
		<h4>八、隐私权政策的更新</h4>
		<p>
			我们可能会适时对本隐私权进行更新。当相关条款发生变更时，我们会在你登录及版本更新时以网站公示、推送通知、弹窗的形式向你展示变更后的隐私权政策。<b>一旦您继续使用跨信通服务，将被视为对更新后的隐私权政策的接受和认可。</b>
		</p>
		<h4>九、我们的联系方式</h4>
		<p>如您对本隐私权政策或对本隐私权政策的实践以及操作上有任何疑问和意见，可通过跨信通的客户隐私专用邮箱（alice@kuaxintong.com）与我们联系，我们将在收到相关信息后的10个工作日内回复您。</p>
	</div>
</template>

<script>
	export default {
		name: "privacyPolicy",
		// 1，打开页面从顶部加载
		updated() { //页面加载完毕事件
			window.scroll(0, 0);
		}
	}
</script>

<style scoped>
	.privacyPolicy {
		width: 100%;
		font-size: 0.14rem;
		text-align: left;
		box-sizing: border-box;
		padding: 0.1875rem;
		line-height: 0.2rem;
	}

	.title {
		text-align: center;
	}

	p {
		text-indent: 2em;
	}
</style>
