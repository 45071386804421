<template>
	<div class="userAgreement">
		<h3 class="title">跨信通注册协议</h3>
		<p class="Standard"><span class="T2">一、特别提示</span></p>
    <p class="Standard">
      <span class="T2"
        >1、概述</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >1.1 本《跨信通用户注册协议》（以下简称“本协议”）是您（以下简称“您”或“用户”）与跨信通网站(网址:www.kuaxintong.com)的经营者深圳跨信通科技有限公司（以下简称“跨信通”）之间关于您注册并使用跨信通以获得跨信通的VAT软件产品使用授权及服务【如增值税税号注册/转代理/注销、申报/补申报/修改申报等相关的软件产品使用授权及软件服务】、EPR软件产品使用授权及服务【如德国包装法/电池法/WEEE、法国包装法/电池法/纺织法/DEEE/玩具法/印刷法/园艺法/体育休闲运动/家具法、英国电池法/WEEE、意大利包装法/电池法/WEEE、西班牙包装法/电池法/WEEE、瑞典包装法/电池法/WEEE、奥地利包装法/电池法/WEEE等合规相关的软件产品使用授权及软件服务】、授权代表产品使用授权及服务【如英国授权代表、欧盟授权代表、美代授权服务】及后续上线的其他软件产品使用授权及服务等（以下简称"跨信通软件产品授权及服务"）。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >1.2 为获得跨信通产品授权及服务，您应当认真阅读并充分了解本协议全部内容条款，在具备完全民事行为能力前提下进行并完成用户信息注册。本协议为您与跨信通之间就购买并使用跨信通软件产品授权及服务所达成的对双方具有法律约束力的协议。如您在进行跨信通用户注册程序过程中点击"同意并遵守"《跨信通用户注册协议》（以下简称“本协议”）并完成用户注册，即表示您已理解并愿意遵守本协议的全部内容，如果您对本协议的条款有疑问的，请通过跨信通网站所载的联系方式进行咨询，跨信通将向您解释条款内容，如果您不同意接受本协议或其任何条款的约定或无法准确理解跨信通对本协议条款的解释，您应当立即终止注册程序及在跨信通网站上的任何操作。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2、注册要求</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2.1 您需具备完全的民事行为能力，能够独立判断并理解本协议条款所表述内容，能够独立承担因账户注册后您所进行的操作或交易行为而产生的相关责任和义务。如您代表公司或其他法律主体进行注册，请务必确保已获得相关主体的授权许可。如您不具备前述能力及要求，请立即终止注册程序及在跨信通平台上的任何操作，且跨信通有权注销您的账号。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2.2 您应保证所填写的资料信息真实、准确、完整，以便跨信通进行审核后为您开通账户以获得跨信通软件产品授权及服务。如您提供的个人资料有任何变动，导致您的实际情况与已填写或提交的信息不一致的，您应及时进行更新。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2.3 注册帐号和密码由您负责设定并保管，密码应当使用由字母、数字、特殊字符组成的密码（6-18位长度），并应定期更改密码。如发现任何人未经授权使用您的注册账户或密码，您需立即通知跨信通。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2.4 您清楚并了解，注册账号作为您在跨信通软件平台进行服务申请、交易的唯一账户，应进行合理妥善保管。您在使用跨信通软件产品授权及服务过程中，对您账号下使用服务的行为、发布或公开的信息，所产生的任何后果由您独立承担。</span
      >
    </p>
    <p class="Standard">
      <span class="T2"
        >2.5 若国家法律法规或政策有特殊规定的，您需要提供真实的身份信息。若您提供的信息不完整或存在虚假等问题，则可能影响您无法使用跨信通软件产品授权及服务或在跨信通软件产品授权及服务过程中受到一定的限制。</span
      >
    </p>
    <p class="P1" />
    <div style="color: #ff0000">
      <p class="Standard"><span class="T2">二、服务内容</span></p>
      <p class="Standard"><span class="T3">1、服务产品及方式</span></p>
      <p class="Standard">
        <span class="T3"
          >1.1 本协议所述的跨信通软件产品授权及服务是用户通过跨信通自主研发的软件服务平台所购买并使用的相关产品授权及软件服务，包括但不限于VAT软件产品使用授权及服务（英国、德国、法国、意大利、西班牙、奥地利、波兰、荷兰、沙特、阿联酋、捷克等国家的VAT和欧盟IOSS等）、EPR软件产品使用授权及服务（德国EPR、法国EPR、西班牙EPR、意大利EPR、瑞典EPR、奥地利EPR等）、授权代表产品使用授权及服务（欧代/英代/美代等）以及未来跨信通不断上线及升级的其他软件产品使用授权及服务。具体以跨信通服务平台上线的服务产品为准。</span
        >
      </p>
      <p class="Standard">
        <span class="T3">1.2 VAT软件产品使用授权及服务的内容：根据相关国家或地区的VAT法规要求，在其境内销售的货物需缴纳增值税。我们的服务是为您注册并取得VAT税号，智能算税、提供明细，按月/按季/按年等其他周期进行申报、智能提醒，挂靠欧洲本土地址，接收信件等，如您未按税务局的要求在规定时间报税和缴纳税金，跨信通有权随时中断或终止服务而无需对您或任何第三方承担任何责任。</span>
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3 EPR软件产品使用授权及服务的内容：德国EPR（包装法、电池法、WEEE）、法国EPR（包装法、电池法、WEEE、纺织法、玩具法、印刷法、园艺法、体育休闲运动、家具法）、英国EPR(电池法、WEEE)、意大利EPR（包装法、电池法、WEEE）、西班牙EPR（包装法、电池法、WEEE）、瑞典EPR（包装法、电池法、WEEE）、奥地利EPR（包装法、电池法、WEEE）等</span
        >
      </p>
      <p class="Standard">
        <span class="T3">1.3.1 包装法的服务内容：根据德国包装法（VerpackG)及法国包装法要求，包装废弃物必须进行回收处理。我们的服务是注册并取得包装法注册号，申报未来一年的包装重量，缴纳包装回收处理费用，基础申报回收服务等。</span>
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.2 电池法的服务内容：根据欧洲电池指令2006/66/EC及德国电池法（BattG)要求，废旧电池必须进行回收处理。我们的服务是为您注册并取得一个电池法注册号，申报未来一年的电池重量，缴纳电池回收处理费用等。</span
        >
      </p>
      <p class="Standard">
        <span class="T3">1.3.3 WEEE的服务内容：根据欧盟WEEE指令及德国电气和电子设备法（ElektroG)要求，电子产品及符合条件的旧设备必须进行回收处理。我们的服务是为您注册并取得WEEE码，每月/年申报1次上一月/上一年销售重量，每年为您缴纳德国官方担保及担保回收费用等。</span>
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.4 纺织法的服务内容：根据法国强制要求，所有在法国销售的纺织品必须按规定附上合规的标签。我们的服务是为您注册并取得一个纺织法注册号，进行年度申报。</span
        >
      </p>
      <p class="Standard">
        <span class="T3">1.3.5 玩具法的服务内容：根据法国强制要求，所有在法国销售的玩具产品必须按规定附上合规的标签。我们的服务是为您注册并取得一个玩具法注册号，进行年度申报。</span>
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.6 印刷法的服务内容：根据法国强制要求，所有在法国销售的印刷产品必须按规定附上合规的标签。我们的服务是为您注册并取得一个印刷法注册号，进行年度申报。</span
        >
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.7 园艺法的服务内容：根据法国强制要求，所有在法国销售的园艺产品必须按规定附上合规的标签。我们的服务是为您注册并取得一个园艺法注册号，进行年度申报。</span
        >
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.8 体育休闲运动法的服务内容：根据法国强制要求，所有在法国销售的体育休闲运动产品必须按规定附上合规的标签。我们的服务是为您注册并取得一个体育休闲运动法注册号，进行年度申报。</span
        >
      </p>
      <p class="Standard">
        <span class="T3"
          >1.3.9 家具法的服务内容：根据法国强制要求，所有在法国销售的家具产品必须按规定附上合规的标签。我们的服务是为您注册并取得一个家具法注册号，进行年度申报。</span
        >
      </p>
      <p class="Standard">
        <span class="T3"
          >1.4 授权代表产品使用授权及服务的内容：欧代/英代/美代负责人是欧盟/英国/美国对产品安全和合规的要求。我们的服务是为您提供在欧盟/英国/美国与产品安全合规方面的负责人服务，欧代/英代/美代联系信息可填写在亚马逊后台以及印刷在产品包装上。如果产品出现安全方面的问题，欧代/英代/美代会协助用户和当地官方进行信息传递，跨信通不负责核验您提供的产品检测报告/技术文档/产品合格声明等相关认证材料是否属实，您应该确保您销售到英国/欧盟的产品符合当地的产品安全法规要求和标准，如因您不能及时或提供无效/不属实的产品检测报告/技术文档/产品合格声明等相关认证材料，跨信通公司有权随时中断或终止向您提供的欧代/英代授权服务而无需对您或任何第三方承担任何责任，跨信通的欧代/英代服务不包括物流/销售/退货/通关/侵权/商标/专利/产品质量/安全事故处理等领域，在后续服务中涉及以上服务内容，跨信通将与您另行协商，如协商不成，跨信通公司有权随时中断或终止向您提供的欧代/英代授权服务而无需对您或任何第三方承担任何责任。</span
        >
      </p>
    </div>
    <p class="Standard">
      <span class="T3"
        >2、用户须知</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.1 您知晓并同意，在使用跨信通软件产品授权及服务时，为满足向您提供产品授权及服务之目的，除注册时提供的信息外，可能还需要您进一步提供与上述产品授权及服务的功能相关的信息（如公司国内外名称/注册地址、公司（个人）店铺名称、公司或个体统一社会信用代码、公司法人或个体工商户中英文姓名/身份证/护照等资料、相关店铺/产品链接、相关的认证检测材料、VAT税号、所在行业、办公电话、电子邮箱、联系人、联系地址等等），为了验证您提供信息的准确性和完整性，保障您的帐号安全，我们会与合法留存您的信息的国家机关、金融机构、企事业单位进行核对。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.2 您知晓并同意，为提供本协议之服务目的，跨信通有权将您提供的信息以合理合法的方式向相关国家地区税局或其它部门提供，或向跨信通合作之机构或关联公司提供，以确保您能获得跨信通软件产品授权及服务。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.3 您知晓并同意，关于新注册用户退款：1）如您在跨信通平台购买相关软件产品授权及服务7天内未提交任何资料，您有权申请退款；2）如您已提交申请材料，跨信通将为您审核资料并递交申请资料至相关机构，即产生相关实际费用，包括但不限于人工费、系统授权费、官方担保费、系统处理费以及部分国家的官费、当地财政代表费用、资料邮递等费用，跨信通有权不予退款；3）如您购买超过7天的，跨信通一概不予退款。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >若您为跨信通的续费客户，则不适用上述退款约定，跨信通有权在收到您的续费支付订单后不予退款。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.4 您知晓并同意，未来跨信通将不断完善现有的软件产品授权及服务，并对现有软件产品授权及服务进行升级，或为用户提供定制化软件服务收费内容。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.5 您知晓并同意，跨信通仅提供与软件服务相关的技术支持，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由您自行承担。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.6 您知晓并同意，为提高您的服务体验和满意度，跨信通有权基于您的操作行为对您的行为数据进行调查研究和分析，从而进一步优化软件产品授权及服务。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.7 您知晓并同意，如您将帐号与微信等第三方帐号进行绑定，因您违反第三方平台相关协议或相关法律规定而导致的账户消除或灭失的，由您自行承担。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.8 您知晓并同意，跨信通可能会就某些软件产品授权及服务的开通，要求您提供更多的资料或信息，做进一步的身份认证或资格验证，您只有在通过这些认证和验证之后，方可获得使用相关软件产品授权及服务的资格。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >2.9 您知晓并同意跨信通在提供服务过程中向您投放各种商业性广告或其他任何类型的商业信息，您愿意接受跨信通通过电子邮件或其他方式向您发送商品促销或其他相关商业信息。</span
      >
    </p>
    <p class="P1" />
    <p class="Standard"><span class="T2">三、使用规则</span></p>
    <p class="Standard"><span class="T4">3.1 您在使用跨信通软件产品授权及服务过程中，应遵守相关国家法律法规、行业规则，不得利用跨信通平台从事不正当竞争行为或损害他人及公共利益或其他违法犯罪行为。</span></p>
    <p class="Standard"><span class="T4">3.2 注册账号作为您在跨信通平台进行服务申请、交易的唯一账户，应进行合理妥善保管。您在使用跨信通软件产品授权及服务过程中，不应将账号、密码转让或出借他人使用，跨信通不会也不能对您账户的操作行为作为是否为您真实意愿的判断。通过您账号下使用服务的行为、发布或公开的信息，所产生的任何后果由您独立承担。除非您已提前向跨信通做出了遗失或被盗的声明或主张。</span></p>
    <p class="Standard"><span class="T4">3.3 对于您通过服务公开发布的任何内容，您同意跨信通在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</span></p>
    <p class="Standard"><span class="T4">3.4 您在使用跨信通软件产品授权及服务时如发现服务系统或其他相关系统的信息安全漏洞需及时通知跨信通公司，禁止对跨信通公司服务系统进行漏洞扫描、渗透测试等可能造成跨信通损失的行为。</span></p>
    <p class="Standard"><span class="T4">3.5 跨信通公司针对某些特定的软件产品授权及服务的使用通过各种方式（包括但不限于网页公告、系统通知、私信、短信提醒、公众号、电话语音等）作出的任何声明、通知、警示等内容视为本协议的一部分，您如使用该等服务，视为您同意该等声明、通知、警示的内容。</span></p>
    <p class="Standard"><span class="T4">3.6 跨信通平台上提供的软件产品授权及服务，如未标明使用期限、或者其标明的使用期限为"永久"、"无限期"或"无限制"的，则其使用期限为自您获得该软件产品授权及服务之日起至该软件产品授权及服务在跨信通下线之日为止。</span></p>
    <p class="Standard"><span class="T4">3.7 您在使用跨信通软件产品及服务过程中不得有以下行为之一，否则跨信通有权终止您的服务，并追究您的法律责任：</span></p>
    <p class="Standard"><span class="T5">3.7.1 删除本产品及其副本上关于著作权的信息；</span></p>
    <p class="Standard"><span class="T5">3.7.2 冒用、盗用他人信息进行账户注册或服务申请；</span></p>
    <p class="Standard"><span class="T5">3.7.3 对本产品进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本产品的源代码；</span></p>
    <p class="Standard"><span class="T5">3.7.4 对跨信通拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</span></p>
    <p class="Standard"><span class="T5">3.7.5 对本产品或者本产品运行过程中释放到任何终端内存中的数据、产品运行过程中用户端与服务器端的交互数据，以及本产品运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非跨信通经授权的第三方工具/服务接入本产品和相关系统；</span></p>
    <p class="Standard"><span class="T5">3.7.6 通过修改或伪造产品运行中的指令、数据，增加、删减、变动产品的功能或运行效果，或者将用于上述用途的产品、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span></p>
    <p class="Standard"><span class="T5">3.7.7 自行或者授权他人、第三方产品对本产品及其组件、模块、数据进行干扰；</span></p>
    <p class="Standard"><span class="T5">3.7.8 其他违反法律法规规定、侵犯他人合法权益、干扰产品正常运营或跨信通未明示授权的行为。</span></p>
    <p class="P1" />
    <p class="Standard"><span class="T2">四、服务变更、中断或终⽌</span></p>
    <p class="Standard">
      <span class="T3"
        >4.1 鉴于软件产品和网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他跨信通无法控制的情形），您同意跨信通有权随时中断或终止部分或全部的软件产品授权及服务，若发生该等中断或终止服务的情形，跨信通将尽可能及时通过网页公告、系统通知、私信、短信、公众号、电话语音或其他合理方式通知受到影响的您。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >4.2 您理解，跨信通公司需要定期或不定期地对提供软件产品授权及服务的平台（如网站基础设施设备等）或相关的设备进行升级、检修或维护，如因此类情况而造成服务在合理时间内的中断，跨信通公司无需为此承担任何责任。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >4.3 如您发生以下任一情形，跨信通有权随时中断或终止向您提供本协议项下的软件产品授权及服务并无需承担任何责任：</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.1 未按要求提供相关资料或提供的资料不真实；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.2 账号注册后持续180天及以上未使用；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.3 违反行业规则或国家相关法律法规，侵犯他人知识产权权益或其他合法权益；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.4 使用收费服务项目时未及时按规定进行付费或到期后未及时续费；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.5 未遵守本协议中规定的使用规则；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.6 跨信通因经营业务调整，服务下线或其他原因导致不能提供相关服务的；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.7 按照政府部门或司法机关的要求；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >4.3.8 其他跨信通认为是符合整体服务需求的特殊情形。</span
      >
    </p>
    <p class="P1" />
    <p class="P4"><span class="T2">五、免责声明</span></p>
    <p class="Standard">
      <span class="T3"
        >5.1 您在使用跨信通软件产品授权及服务的过程中应遵守相关国家法律法规及政策规定，因您违反相关国家法律法规或行业规定、规则、要求等而受到的任何处罚与跨信通无关，我们将会提醒您进行注意，但不对您使用服务而产生的行为后果承担任何责任。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >5.2 您通过跨信通软件产品授权及服务发布的任何信息，及传递的任何观点不代表跨信通之立场，跨信通亦不对其完整性、真实性、准确性负责。您对于可能会接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行谨慎做出判断。由此产生的任何责任和纠纷，您自行承担。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >5.3 鉴于外部链接指向的网页内容非跨信通实际控制，因此跨信通无法保证为向您提供便利而设置的外部链接的准确性和完整性，亦不对外部链接进行负责。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >5.4 因网站系统发生故障影响到本服务的正常运行，跨信通承诺将及时处理、修复。但您因此而产生的经济和精神损失，跨信通不承担任何责任。此外，跨信通保留不经事先通知进行系统维护、升级或其他目的暂停本服务的权利，由此产生的损失跨信通不承担任何责任。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >5.5 因出现以下情形之一而导致您所遭受的直接或间接损失，跨信通不承担任何责任：</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >5.5.1 出现不可抗力因素，包括但不限于战争、台风、水灾、火灾、地震、罢工、暴动、计算机病毒或黑客攻击等情形；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >5.5.2 因账号被盗或遗失未及时告知跨信通，导致财产损失或处罚的；</span
      >
    </p>
    <p class="Standard">
      <span class="T4"
        >5.5.3 违反法律或本协议的条款，导致账号被限制或注销</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >5.6 您在使用跨信通软件产品授权及服务过程中理解及操作有误或非您真实性意愿进行相关服务申报，因跨信通不能也不会对您的行为真实性做出判断，由此产生的责任和后果由您自行承担。</span
      >
    </p>
    <p class="P1" />
    <p class="P4"><span class="T2">六、知识产权</span></p>
    <p class="Standard">
      <span class="T3"
        >6.1 跨信通软件服务平台所提供的任何图片、文字、视频、音频、资料、设计、技术、编码等知识产权权利完全归属于跨信通所有，您不能进行任何的侵占及使用，亦不能对跨信通所享有的知识产权进行二次创作或反向工程，或其他使跨信通知识产权权益受损的行为，否则跨信通将依法追究您的侵权责任。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >6.2 如您发现任何侵权跨信通知识产权之行为，不得以您自己的名义去追究或维权，跨信通并没有授权您享有对跨信通知识产权维权的权利，您需告知并配合跨信通去维权，跨信通将视情况对您进行补偿及奖励。</span
      >
    </p>
    <p class="P1" />
    <p class="P4"><span class="T2">七、通知送达</span></p>
    <p class="Standard">
      <span class="T3"
        >7.1 本协议项下跨信通对于您所有的通知均可通过网页公告、系统通知或您在跨信通平台所留存的电话、电子邮件或其他接收方式进行送达。该等通知于发送之日视为您已收到并知晓。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >7.2 您对于跨信通的通知应当通过跨信通对外正式公布的通信地址、传真号码、电子邮件地址等方式进行送达。</span
      >
    </p>
    <p class="P1" />
    <p class="P4"><span class="T2">八、附则</span></p>
    <p class="Standard">
      <span class="T3"
        >8.1 本协议的订立、履行、解释及争议均适用中华人民共和国法律。如协议任一条款与中华人民共和国法律相抵触而无效，则该部分条款无效，但不影响其它条款之法律效力。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >8.2 因本协议所引起的您与跨信通的任何纠纷或争议，首先应本着友好协商原则进行解决，如果协商不成的，您在此完全同意将纠纷或争议提交至深圳市龙岗区人民法院通过诉讼解决。</span
      >
    </p>
    <p class="Standard">
      <span class="T3"
        >8.3 本协议中的标题仅为方便而设，在解释本协议时应被忽略。</span
      >
    </p>
    </div>
</template>

<script>
  export default {
    name: "userAgreement",
	// // 1，打开页面从顶部加载
	updated() { //页面加载完毕事件
		window.scroll(0, 0);
	}

	}
</script>

<style scoped>
	.userAgreement {
		width: 100%;
		font-size: 0.14rem;
		text-align: left;
		box-sizing: border-box;
		padding: 0.1875rem;
		line-height: 0.2rem;
	}
	.title {
		text-align: center;
		

	}
	p {text-indent:2em;}
	.userAgreement li {
		list-style: none;
	}
</style>
